// Fixed Header

.session-footer {
    height: $app-session-footer-height;
    bottom: 1%
}

.video-tile-control{
    height: $video-tile-control-div-height;
    position: absolute;    
    /* left: 25%;  */   
}

.bottom-control-max {
    top: 2%;
}

.bottom-control-grid {
    top: 3%;
}

.control-width-max {
    max-width: 32% !important;
}

.control-width-max-chat-open {
    max-width: 50%;
}

.control-width-grid {
    max-width: 50% !important;
}

.control-width-grid-eight {
    max-width: 70%;
}

.control-width-grid-chat-open {
    max-width: 75%;
}

.top-control-width-grid {
    max-width: 10% !important;
}

.ms-6 {
    margin-left: 4rem !important;
}

.ms-7 {
    margin-left: 4.5rem !important;
}

.ms-40 {
    margin-left: 40% !important;
}

.max-view-control-class {
    width: calc((100vh - 60px - 90px - 20px)* 16 / 9);
    min-width: calc((100vh - 60px - 90px - 20px)* 16 / 9);
    background: $app-header-bg;
    box-shadow: 0 0.46875rem 2.1875rem rgb(205,215,239), 0 0.9375rem 1.40625rem rgb(205,215,239), 0 0.25rem 0.53125rem rgba(205,215,239), 0 0.125rem 0.1875rem rgb(205,215,239);
}

.navbar-gradient{
    background-image: linear-gradient(to left, rgb(121,102,183) 42%, rgb(97,201,115) 60%);
}

.second-control-placement {
    /* margin-bottom: 33% !important; */
}

.bg-active {
    background-color: #d5d5f5;
}

.second-card-close-width {
    width: 10%;
}


