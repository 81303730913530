

.vdo-content{
    height: calc(100vh - $app-header-height  - 2 * $app-session-footer-height);
    min-height: calc(100vh - $app-header-height  - 2 * $app-session-footer-height);
    // background: green;
    position: relative;
    .max-video {
        position: absolute;
        top:20px;
        bottom: 0;
        left: 0;
        right: 0;    
        margin: auto;        
    }
    .vdo-content-chat-closed {
        width: calc(((100vh - $app-header-height  - 2 * $app-session-footer-height)*16)/9);
        min-width: calc(((100vh - $app-header-height  - 2 * $app-session-footer-height)*16)/9);
    }
    .vdo-content-chat-open {
        height: calc(100vh - $app-header-height  - 2 * $app-session-footer-height - ($app-header-height/3));
        min-height: calc(100vh - $app-header-height  - 2 * $app-session-footer-height - ($app-header-height/3));
        width: calc(((100vh - $app-header-height  - 2 * $app-session-footer-height - ($app-header-height/3))*16)/9);
        min-width: calc(((100vh - $app-header-height  - 2 * $app-session-footer-height - ($app-header-height/3))*16)/9);
    }
}

.side-panel {
    background-color: white;   
    transition: all .3s ease-out; 
}

.side-panel-close {
    transition: all .3s ease;
    width: $shape-picker-width-collapsed;
    min-width: $shape-picker-width-collapsed;
    flex: 0 0 $shape-picker-width-collapsed;
}

.video-rounded {
    border-radius: 20px;
}
.inherit-content{
    height: inherit;
}

video.fit-cover {
    object-fit: cover !important;
}
  
video.fit-contain {
    object-fit: contain !important;
}

.grid-video-tile-container {
    position: relative;
}