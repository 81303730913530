/* body {
    font-size: 16px;
} */

.animated-line-header {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}


.line {
    width: 100%;
    cursor: pointer;
    padding: 10px;
    color: #000;
    transition: all 0.3s ease;
    text-align: center;
}

.line:hover {
    color: #007bff;
}

.line.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
    text-align: center;
}

.tab-title {
    font-size: 18px;
    font-weight: 450;

}

.cursor-pointer {
    cursor:pointer;
}

.ms-pointer {
    cursor: pointer;
    height: 100%;
}

.ms-pointer:hover {
    background: #fff;
    box-shadow: 0px 0px 8px 0px;
    cursor: pointer;
}

.round-btn-input{
    position: relative;
}

.round-btn {
    position: absolute;
    top: 1.8rem;
    /* right: 1.8rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2.5rem;
    /* border-radius: 50%; */
    color: #000000;
    font-size: 1.2rem;
    cursor: pointer;
    /* border: 1px solid grey; */
}

.mr-4{
    margin-right: 4rem;
}

.b-none{
    border:none;
}


.round-btn:hover {
    border-color: #007bff; 
}