.shape-picker {
    /* .shape-picker { */
        flex: 0 0 $shape-picker-width !important;
        width: $shape-picker-width !important;
        transform: translateX(-$shape-picker-width);
    /* } */
}

.shape-picker-close {
    /* .shape-picker { */
        height: 10%;
        transition: all .3s ease;
        width: $shape-picker-width-collapsed;
        min-width: $shape-picker-width-collapsed;
        flex: 0 0 $shape-picker-width-collapsed;
    /* } */
}

.shape-picker {

    /* .shape-picker { */

      transform: translateX(0);
    /* } */
}

.rotate-360 {
    transform: rotate(0deg);
    transition: transform 1s ease-in;
}

.rotate-360.expand {
    transform: rotate(360deg);
    transition: transform 1s ease-in;
}