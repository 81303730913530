// User Pages

.bg-circle-img {
  height: 100%;
  width: 66%; 
  background: url('../../assets/utils/images/Background.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.login-logo {
  height: 200px;
  width: 100%;  
  background: url('../../assets/utils/images/telepresenz-logo-new.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background-size: cover !important;
  background: url('../../assets/utils/images/new_header_logo.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background-size: cover !important;
  background: url('../../assets/utils/images/new_header_logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}