// Wizard Multi Step

@use "sass:math";

.forms-wizard {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  @include border-radius($border-radius);

  li {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 90%;
    cursor: pointer;
    font-size: math.div($font-size-lg, 1.1);
    padding: math.div($layout-spacer-x, 1.5) 0;
    // color: $gray-500;
    color: #545cd8;
    background: #e0f3ff ;   // this is the default background color of the steps 
    position: relative;

    &::after,
    &::before {
      position: absolute;
      height: 4px;
      top: 50%;
      margin-top: -(math.div($layout-spacer-x, 1.5));
      width: 50%;
      content: '';
      background: $gray-300;
      color: #545cd8 !important;
      z-index: 5;
      transition: all .2s;
    }

    &::after {
      left: 50%;
    }

    &::before {
      left: 0;
    }

    &:first-child {
      &::before {
        @include border-start-radius(20px);
      }
    }

    &:last-child {
      &::after {
        @include border-end-radius(20px);
      }
    }

    em {
      font-style: normal;
      font-size: $h6-font-size;
      background: $gray-400;
      // color: $white;
      text-align: center;
      padding: 0;
      width: 20px;
      height: 20px;
      line-height: 20px;
      @include border-radius(50px);
      display: block;
      margin: 0 auto 0.5rem;
      position: relative;
      z-index: 7;
      transition: all .2s;
    }

    .taskPlusIcon {
      font-style: normal;
      font-size: $h5-font-size;
      text-align: center;
      padding: 0 0 10px 0;
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: block;
      margin: 1rem 0.5rem;
      position: relative;
      z-index: 7;
    }   
    

    &.form-wizard-step-doing {
      // color: $gray-700;
      color: #545cd8;
      em {
        background: $primary;
      }

      &::before {
        background: $primary;
      }
    }

    &.form-wizard-step-done {
      em {
        font-family: 'Linearicons-Free';
        @extend .lnr-checkmark-circle !optional;
        // background: $primary;
        
        overflow: hidden;
        &::before {
          width: 20px;
          height: 20px;
          font-size: 1rem;
          line-height: 20px;
          text-align: center;
          display: none;
        }
      }

      .taskPlusIcon {        
        &::before {
          width: 20px;
          height: 20px;
          font-size: 1rem;
          line-height: 20px;
          text-align: center;
          display: block;
          
        }
      }

      &::after,
      &::before {
        background: $success;
        
      }
    }

    &:hover {
      color: $gray-600;
    }
  }
}


// Form Wizard Alternate

.forms-wizard-alt {
  .forms-wizard {
    li {
      font-size: $font-size-base;

      em {
        width: 14px;
        height: 14px;
        line-height: 14px;
        text-indent: -999rem;
        border: $white solid 2px;
        margin: -7px auto 0;

      }

      .taskPlusIcon {
        width: 14px;
        height: 14px;
        line-height: 14px;
        text-indent: -999rem;
        margin: -7px auto 0;

      }
    }
  }
}

.forms-wizard-vertical {
  .forms-wizard {
    display: block;
    width: 27%;
    float: left;
    padding: 0 $layout-spacer-x $layout-spacer-x 0;
    overflow-x: hidden;
    max-height: 500px;
    overflow-y: auto;

    li {
      text-align: left;
      display: flex;
      align-items: center;
      align-content: flex-start;
      padding: math.div($layout-spacer-x, 2);
      margin-bottom: math.div($layout-spacer-x, 3);
      @include border-radius($border-radius-lg);
      transition: all .2s;

      &::before,
      &::after {
        display: none;
      }

      em {
        margin: 1.3px math.div($layout-spacer-x, 2) 0 0;
      }

      .taskPlusIcon {
        margin: 0 math.div($layout-spacer-x, 2) 0 math.div($layout-spacer-x, 2);
      }

      &:hover {
        background: $gray-200;
      }

      &.form-wizard-step-doing {
        background: $primary;   //active background color for steps
        color: $white;
        // color: #545cd8;
        // width: 90%;

        em {
          background: rgba(255,255,255,.2);
        }
      }
    }
  }

  .form-wizard-content {    
    width: 70%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 550px !important;  
    height: 513px;
  }
}


@media screen and (min-width: 360px) and (max-width: 812px) {
  .forms-wizard-vertical {
    .forms-wizard {
      display: block;
      width: 50%;
      float: left;
      padding: 0 $layout-spacer-x $layout-spacer-x 0;
      overflow-x: hidden;
      max-height: 500px;
      overflow-y: auto;
  
      li {
        text-align: left;
        display: flex;
        align-items: center;
        align-content: flex-start;
        padding: math.div($layout-spacer-x, 2);
        margin-bottom: math.div($layout-spacer-x, 3);
        @include border-radius($border-radius-lg);
        transition: all .2s;
  
        &::before,
        &::after {
          display: none;
        }
  
        em {
          margin: 0 math.div($layout-spacer-x, 2) 0 0;
        }

        .taskPlusIcon {
          margin: 0 math.div($layout-spacer-x, 2) 0 0;
        }
  
        &:hover {
          background: $gray-200;
        }
  
        &.form-wizard-step-doing {
          background: $primary;
          color: $white;
         
  
          em {
            background: rgba(243, 34, 34, 0.2);
          }
        }
      }
    }
  
    .form-wizard-content {
      width: 50%;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 500px;
    }
  }
}

.form-wizard-subclass {
  padding-left: 1.75rem !important;
}

.form-wizard-2nd-subclass {
  padding-left: 3.0rem !important;
}

.form-btn-container {
  /* display: flex;  
  justify-content: center;  
  align-items: center;  */
  button {
    margin-right: 20px;
  }
  .btn-icon {
    font-size: 35px;
    /* margin-left: px; */
    margin-right: 35px;
    color: $brand;
  }
  .inner-btn-container {
    display: flex;  
    justify-content: center;  
    align-items: center;
    .btn-end{
      padding-right: 20px;
    }
  }
}