.ring-container {
    position: relative;
    width: 15px;
    top: 14%;
}
.circle {
    width: 9px;
    height: 9px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    //top: 8px; //-11px;
    left: 0px;
}

.ringring {
    border: 3px solid #62bd19;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    height: 17px;
    width: 17px;
    position: absolute;
    left: -4px;
    //top: 4px;//-15px;
    animation: pulsate 1s ease-out;
    -webkit-animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0
}
@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}