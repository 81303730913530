// Input groups

.input-group {
  .input-group-prepend {
    div:not([class]) .react-datepicker__input-container,
    & + div .react-datepicker__input-container {
      .form-control {
        @include border-start-radius(0);
        @include border-end-radius($input-border-radius !important);
      }
    }
  }

  & > div:not([class]) {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & .react-datepicker__input-container {
      .form-control {
        @include border-end-radius(0);
      }

    }
  }
}

.input-group {
  .input-group-prepend {
    & + div {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
    }
  }

  & > .react-datepicker-wrapper {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    & > .react-datepicker__input-container > .form-control {
      @include border-start-radius(0);
    }
  }
}

// Forms Legend

legend {
  font-size: $font-size-base;
  font-weight: bold;
}

// Headings

.form-heading {
  font-size: $font-size-lg;
  margin: 0;
  color: $primary;

  p {
    color: $gray-600;
    padding: 0.3rem 0 0;
    font-size: $font-size-base;
  }
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.bg-created{
  background-color: #d19a52 !important;
}
.bg-assigned{
  background-color: #bfe8f2 !important;
}
.bg-in-progress{
  background-color: #eaf099 !important;
}
.bg-finished{
  background-color: #defde0 !important;
}
.bg-white{
  background-color: #ffffff !important;
}
/*TP-3220, TP-3222, TP-3223 -- new color background for New Task created*/
.bg-new-task{
  background-color: #a432d1 !important;
}

.task-card {
  padding: 10px;
  border-radius: 10px;
}

.category-plus-icon {
  margin-top: 31px;
  font-size: 30px;
}

.error-msg {
  color: $danger;
  margin-top: 1rem !important;
}

.hint-msg-passed {
  color: $success;
}

.hint-msg-failed {
  color: $danger;
}